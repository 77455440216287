import { useEffect, useMemo, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import displayToast from "components/Toast/displayToast";

import useWorkspaceAppsApiConsumer from "consumers/useWorkspaceAppsApiConsumer";

import { useAppContext } from "context/App/App.context";
import { useAppSelectionContext } from "context/App/AppSelectionContext";

import { isMobileOS } from "models";

import { useAdministrationDeps } from "../Administration";
import { determineInitialSelectedAppId } from "../util/determineInitialSelectedApp";

export default function useAppsUsersViewConsumer() {
  const { administrationService } = useAdministrationDeps();
  const { fetchAllApps } = useWorkspaceAppsApiConsumer();
  const { appKey } = useParams();

  const { apps, loading: appsLoading } = useAppContext();
  const { selectedWorkspace, selectedApp: globalSelectedApp } = useAppSelectionContext();

  const [localSelectedAppId, setLocalSelectedAppId] = useState<string | undefined>(
    determineInitialSelectedAppId(globalSelectedApp, apps, window.location.search, appKey),
  );

  const localSelectedApp = useMemo(() => apps.find((app) => app.id === localSelectedAppId), [localSelectedAppId, apps]);

  const selectedWorkspaceId = useMemo(() => selectedWorkspace?.id, [selectedWorkspace]);

  const [loading, setLoading] = useState(false);

  async function getCertificate() {
    try {
      if (selectedWorkspace && localSelectedApp) {
        const { data } = await administrationService.getCertificate(selectedWorkspace.id, localSelectedApp.id);

        return data;
      }
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: "There was an error while getting certificate",
      });
      return;
    }
  }

  const deleteCertificate = async () => {
    try {
      if (!selectedWorkspace?.id || !localSelectedApp) return;

      setLoading(true);

      await administrationService.deleteCertificate(selectedWorkspace.id, localSelectedApp.id);

      await refetchCertificate();

      displayToast({
        content: "Certificate has been successfully deleted!",
      });
    } catch (error) {
      displayToast({
        title: "Something went wrong",
        content: "There was an error while deleting file",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!selectedWorkspace || localSelectedAppId) return;
    fetchAllApps(selectedWorkspace.id).then((allApps) => {
      setLocalSelectedAppId(determineInitialSelectedAppId(globalSelectedApp, allApps, window.location.search, appKey));
    });
  }, [selectedWorkspace, fetchAllApps, localSelectedAppId, globalSelectedApp, appKey]);

  useEffect(() => {
    if (appKey) {
      setLocalSelectedAppId(determineInitialSelectedAppId(globalSelectedApp, apps, window.location.search, appKey));
    }
  }, [appKey, apps, globalSelectedApp]);

  const { data: certificate, refetch: refetchCertificate } = useQuery(
    [`certificate`, { localSelectedAppId, selectedWorkspaceId }],
    getCertificate,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled:
        Boolean(localSelectedAppId) &&
        selectedWorkspace &&
        Boolean(selectedWorkspaceId) &&
        localSelectedApp &&
        localSelectedApp.active &&
        isMobileOS(localSelectedApp) &&
        !appsLoading,
    },
  );

  return {
    allApps: apps,
    loading,
    localSelectedApp,
    certificate,
    refetchCertificate,
    deleteCertificate,
  };
}
